import React from "react";
import { Helmet } from "react-helmet";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const RequestDemo = () => (
  <main>
    <CookieCompliance />
    <Helmet>
      <meta http-equiv="refresh" content="0; URL='/demo-call/'" />
    </Helmet>
  </main>
);

export default RequestDemo;
